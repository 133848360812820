<template>
    
  <v-card>
    <v-card-title>
      Crop
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="dialog = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        New Crop
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text v-if="display == 1">
      <template>
        <v-card-title class="pl-0 pr-0 pt-0">
          <!-- <v-btn
            color="secondary"
            @click="dialog = true"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            New Crop
          </v-btn> -->
          <!-- <v-spacer></v-spacer> -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              offset-sm="6"
            >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="cropHeaders"
          :items="cropsTable"
          :items-per-page="5"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.action`]="props">
            <template>
              <v-icon
              dense
              class="mr-2 blue--text" @click="formCrop = props.item, dialog = true, mode = 'edit'"
              >
                  mdi-pencil-outline
              </v-icon>

              <v-icon
              dense
              class="mr-2 red--text" @click="deleteCropData = props.item, deleteCropDialog = true"
              >
                  mdi-trash-can-outline
              </v-icon>
            </template>
          </template>
        </v-data-table>
      </template>
    </v-card-text>

    <v-card-text v-if="display == 2">
      <v-card-title class="pl-0 pr-0 pt-0">
          <v-btn
            color="secondary"
            @click="display = 1"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </v-card-title>
        <template>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                Fill in Crop Profile
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                Fill in Crop Details
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="formCrop.name"
                    :rules="[v => !!v || 'Item is required']"
                    label="Crop Name"
                    required
                    outlined
                    dense
                    class="mt-1"
                  ></v-text-field>

                  <v-select
                    v-model="formCrop.type"
                    :items="cropType"
                    :rules="[v => !!v || 'Item is required']"
                    label="Crop Type"
                    required
                    outlined
                    dense
                  ></v-select>

                  <v-select
                    v-model="formCrop.unit"
                    :items="cropUnit"
                    :rules="[v => !!v || 'Item is required']"
                    label="Measurement Unit"
                    required
                    outlined
                    dense
                  ></v-select>

                  <!-- <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="validate"
                  >
                    Validate
                  </v-btn>

                  <v-btn
                    color="error"
                    class="mr-4"
                    @click="reset"
                  >
                    Reset Form
                  </v-btn>

                  <v-btn
                    color="warning"
                    @click="resetValidation"
                  >
                    Reset Validation
                  </v-btn> -->
                  <v-btn
                    color="primary"
                    @click="e1 = 2"
                    class="mr-2"
                  >
                    Continue
                  </v-btn>
                  <!-- <v-btn text>
                    Cancel
                  </v-btn> -->
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2" class="pa-2">
                <v-card-title class="pl-0 pr-0">
                  <v-btn
                    color="secondary"
                    @click="dialog = true"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    New Crop Details
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                  :headers="cropDetailsHeaders"
                  :items="cropsDetailsTable"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>

                <v-btn
                  color="primary"
                  @click="e1 = 1"
                  class="mt-2 mr-2"
                >
                  Previous
                </v-btn>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </template>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 secondary white--text justify-center" v-if="mode == 'new'">
          New Crop
        </v-card-title>
        <v-card-title class="text-h5 secondary white--text justify-center" v-else>
          Update Crop
        </v-card-title>

        <v-card-text class="pt-2">
          <v-row>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              v-model="formCrop.Crop"
              label="Crop Name"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
              <v-select
                :items="cropSoilType"
                label="Soil Type"
                required
                outlined
                hide-details="auto"
                dense
                v-model="formCrop.Soil_Type"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Minimum Temperature (℃)"
                v-model="formCrop.Temperature_Min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Maximum Temperature (℃)"
                v-model="formCrop.Temperature_Max"
              ></v-text-field>
            </v-col>
            
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Minimum Rainfall (mm)"
                v-model="formCrop.Rainfall_Min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Maximum Rainfall (mm)"
                v-model="formCrop.Rainfall_Max"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Minimum Elevation"
                v-model="formCrop.Elevation_Min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Maximum Elevation"
                v-model="formCrop.Elevation_Max"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Minimum pH"
                v-model="formCrop.PH_Min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Maximum pH"
                v-model="formCrop.PH_Max"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-switch
              v-model="formCrop.Map_Visible_Switch"
              flat
              :label="`Visible on Map : ${formCrop.Map_Visible_Switch}`"
            ></v-switch>

            </v-col>
            
            <!-- <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Minimum Land Height (m)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              dense
            >
              <v-text-field
                dense
                outlined
                hide-details="auto"
                required
                label="Maximum Land Height (m)"
              ></v-text-field>
            </v-col> -->
            <!-- <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Season"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Duration"
            ></v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="submitNewCrop()"
            outlined
            v-if="mode == 'new'"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            @click="submitUpdateCrop()"
            outlined
            v-else
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-dialog
      v-model="deleteCropDialog"
      width="600"
      scrollable
      >
      <v-card>
          <v-card-title class="justify-center title-bar-modul">
              <h4>Delete Crop </h4>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mt-2">
            <v-row>

              <v-col
                  class="d-flex mt-3"
                  cols="12"
                  sm="3"
              >
                  <v-text-field
                      v-model="deleteCropData.ID"
                      outlined
                      label="Crop ID"
                      dense
                      hide-details="auto"
                      readonly
                  ></v-text-field>
              </v-col>

              <v-col
                  class="d-flex mt-3"
                  cols="12"
                  sm="9"
              >
                  <v-text-field
                      v-model="deleteCropData.Crop"
                      outlined
                      label="Crop ID"
                      dense
                      hide-details="auto"
                      readonly
                  ></v-text-field>
              </v-col>

            </v-row>
          </v-card-text>
              
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                outlined
                @click="deleteCropDialog = false; deleteCropData = ''"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red"
                outlined
                @click="deleteCrop();"
            >
                Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <div class="text-center">
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="success"
        >

        {{ snackbarMsg }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
      </div>
    </template>

  </v-card>

</template>

<script>
// -- import something here --
import axios from 'axios';

export default {

  data: () => ({
    mode: 'new',
    display: 1,
    dialog: false,
    e6: 1,
    e1: 1,
    valid: true,
    cropType: ['Fruits', 'Herbs', 'Vegetables'],
    cropUnit: ['kg','tangkai','tongkol'],
    cropSoilType: ['Air', 'Bandar', 'Bekas Lombong', 'Lombong', 'Tanah Aluvium', 'Tanah Berpasir', 'Tanah Bris', 'Tanah Gambut', 'Tanah Mineral'],
    search: '',
    // cropHeaders: [
    //   {
    //     text: 'Crop',
    //     align: 'start',
    //     sortable: false,
    //     value: 'crop',
    //   },
    //   { text: 'Type', value: 'type' },
    //   { text: 'Description', value: 'description' },
    //   { text: 'Measurement Unit', value: 'unit' },
    //   { text: 'Action', value: 'action' },
    // ],
    cropHeaders: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'Crop',
      },
      { text: 'Soil Type', value: 'Soil_Type' },
      { text: 'Minimum Temperature (°C)', value: 'Temperature_Min' },
      { text: 'Maximum Temperature (°C)', value: 'Temperature_Max' },
      // { text: 'Minimum pH', value: 'PH_Min' },
      // { text: 'Maximum pH', value: 'PH_Max' },
      { text: 'Minimum Rainfall (mm)', value: 'Rainfall_Min' },
      { text: 'Maximum Rainfall (mm)', value: 'Rainfall_Max' },
      { text: 'Minimum Elevation', value: 'Elevation_Min' },
      { text: 'Maximum Elevation', value: 'Elevation_Max' },
      { text: 'Action', value: 'action', width: '10%' },
    ],
    cropsTable: [],

    

    formCrop: {
      ID: "",
      Crop: "",
      CropPrice_Name: "",
      Status: "",
      Soil_Type: "",
      Soil_Type2: "",
      Soil_Type3: "",
      Price_Unit: "",
      Price_2021: "",
      Price_2022: "",
      Price_2023: "",
      PH_Min: "",
      PH_Max: "",
      Rainfall_Min: "",
      Rainfall_Max: "",
      Temperature_Min: "",
      Temperature_Max: "",
      Elevation_Min: "",
      Elevation_Max: "",
      Season: "",
      Duration: "",
      Map_Visible: "",
      Map_Visible_Switch: false
    },

    cropDetailsHeaders: [
      {
        text: 'Soil Type',
        align: 'start',
        sortable: false,
        value: 'soilType',
      },
      { text: 'Minimum pH', value: 'minpH' },
      { text: 'Maximum pH', value: 'maxpH' },
      { text: 'Minimum Rainfall (mm)', value: 'minRainfall' },
      { text: 'Maximum Rainfall (mm)', value: 'maxRainfall' },
      { text: 'Minimum Temperature (°C)', value: 'minTemp' },
      { text: 'Maximum Temperature (°C)', value: 'maxTemp' },
      { text: 'Minimum Land Height (m)', value: 'minLand' },
      { text: 'Maximum Land Height (m)', value: 'maxLand' },
      { text: 'Season', value: 'season' },
      { text: 'Duration', value: 'duration' },
      { text: 'Action', value: 'action' },
    ],
    cropsDetailsTable: [],

    snackbar: false,
    timeout: 3000,
    snackbarMsg: "",

    deleteCropDialog: false,
    deleteCropData: "",


  }),

  mounted() {
    this.getCropList();
  },

  methods: {
    getCropList(){
      axios.get('https://atania.app/api/crop/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          // console.log(response);
          for(let k in response.data){
            if(response.data[k].Map_Visible == 0){
              response.data[k].Map_Visible_Switch = false
            } else if (response.data[k].Map_Visible == 1) {
              response.data[k].Map_Visible_Switch = true
            }
          }

          this.cropsTable = response.data;

      })
      .catch(error => {
          console.log(error);
      })
    },

    submitNewCrop(){

      const payload = {
        ID: "",
        Crop: this.formCrop.Crop,
        CropPrice_Name: "",
        Status: "",
        Soil_Type: this.formCrop.Soil_Type,
        Soil_Type2: "",
        Soil_Type3: "",
        Price_Unit: "",
        Price_2021: "",
        Price_2022: "",
        Price_2023: "",
        PH_Min: this.formCrop.PH_Min,
        PH_Max: this.formCrop.PH_Max,
        Rainfall_Min: this.formCrop.Rainfall_Min,
        Rainfall_Max: this.formCrop.Rainfall_Max,
        Temperature_Min: this.formCrop.Temperature_Min,
        Temperature_Max: this.formCrop.Temperature_Max,
        Elevation_Min: this.formCrop.Elevation_Min,
        Elevation_Max: this.formCrop.Elevation_Max,
        Season: "",
        Duration: "",
        Map_Visible: this.formCrop.Map_Visible_Switch == true ? 1 : 0

      };

      axios.post('https://atania.app/api/crop/new.php', payload, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
        console.log(response);   
        this.snackbarMsg = 'New crop successfully added!';
        this.snackbar = true;
        this.getCropList();
        this.dialog = false;
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    submitUpdateCrop(){

      const payload = {
        ID: this.formCrop.ID,
        Crop: this.formCrop.Crop,
        CropPrice_Name: this.formCrop.CropPrice_Name,
        Status: this.formCrop.Status,
        Soil_Type: this.formCrop.Soil_Type,
        Soil_Type2: this.formCrop.Soil_Type2,
        Soil_Type3: this.formCrop.Soil_Type3,
        Price_Unit: this.formCrop.Price_Unit,
        Price_2021: this.formCrop.Price_2021,
        Price_2022: this.formCrop.Price_2022,
        Price_2023: this.formCrop.Price_2023,
        PH_Min: this.formCrop.PH_Min,
        PH_Max: this.formCrop.PH_Max,
        Rainfall_Min: this.formCrop.Rainfall_Min,
        Rainfall_Max: this.formCrop.Rainfall_Max,
        Temperature_Min: this.formCrop.Temperature_Min,
        Temperature_Max: this.formCrop.Temperature_Max,
        Elevation_Min: this.formCrop.Elevation_Min,
        Elevation_Max: this.formCrop.Elevation_Max,
        Season: this.formCrop.Season,
        Duration: this.formCrop.Duration,
        Map_Visible: this.formCrop.Map_Visible_Switch == true ? 1 : 0
      };

      console.log(payload);

      axios.post('https://atania.app/api/crop/edit.php', payload, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
        console.log(response);   
        this.snackbarMsg = 'Crop successfully updated!';
        this.snackbar = true;

        this.mode = 'new'

        this.formCrop = {
          ID: "",
          Crop: "",
          CropPrice_Name: "",
          Status: "",
          Soil_Type: "",
          Soil_Type2: "",
          Soil_Type3: "",
          Price_Unit: "",
          Price_2021: "",
          Price_2022: "",
          Price_2023: "",
          PH_Min: "",
          PH_Max: "",
          Rainfall_Min: "",
          Rainfall_Max: "",
          Temperature_Min: "",
          Temperature_Max: "",
          Elevation_Min: "",
          Elevation_Max: "",
          Season: "",
          Duration: ""
        }

        this.getCropList();
        this.dialog = false;
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    deleteCrop(){

      axios.get('https://atania.app/api/crop/delete.php?id=' + this.deleteCropData.ID, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          if(response.data.msg == 'succesful') {
            this.snackbarMsg = 'Your data successfully deleted!';
            this.snackbar = true;
            this.getCropList();
            this.deleteCropDialog = false;
          }
          

      })
      .catch(error => {
          console.log(error);
      })
    }
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


